import { StaticImage } from "gatsby-plugin-image"

const NewsArticle = () => {
  const article = {
    title: "The quick brown fox jumps over the lazy dog",
    date: "22 July 2022",
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean id tellus dignissim, pellentesque orci sed, malesuada purus. Duis blandit nibh in blandit commodo. Curabitur massa ex, finibus eu tortor sit amet, porttitor tristique nisl. Integer convallis dapibus nisl, vitae pharetra ligula. Nulla dapibus arcu quis velit iaculis luctus. Sed in dignissim justo, sit amet commodo turpis. In hac habitasse platea dictumst. Vivamus ac lacus vitae libero placerat dignissim ut ut ex. Morbi sit amet libero varius, cursus lacus sed, scelerisque orci.

    Donec sed arcu arcu. Sed sodales est purus, ac dictum quam vulputate ac. Proin et justo congue, vestibulum odio eu, vestibulum velit. Aenean aliquam, felis et varius faucibus, diam sem scelerisque sem, quis ultrices nibh justo a lorem. Aliquam ut luctus libero. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin lacinia, ligula id aliquet viverra, libero risus luctus sapien, vitae luctus dui dolor eget dui. Etiam ipsum odio, fringilla a eleifend vel, vulputate eu sapien. Etiam quis velit hendrerit, sodales mauris semper, sagittis turpis. Phasellus ac diam id est tempus tincidunt. Integer finibus nulla nisi, a interdum lorem congue ut. Curabitur ex arcu, tempus a massa ac, tincidunt placerat metus.
    `,
  }
  return (
    <section className="news-container">
      <div className="content-detail">
        <h2 className="news-detail-title pt-5">{article.title}</h2>
        <h2 className="news-detail-date">{article.date}</h2>
        <StaticImage
          src="../../../../images/clinical5.jpg"
          layout="constrained"
          height={500}
          width={800}
        />
        <p className="news-detail-content">{article.content}</p>
      </div>
    </section>
  )
}

export default NewsArticle
